import React, { useState } from 'react';

interface ProfileButtonProps {
  picture: string;
  availabilityColor: string;
  label?: string;
  onToggle: () => void;
  toggleSidebar?: boolean;
}

export default function ProfileButton({
  picture,
  availabilityColor,
  label,
  onToggle,
  toggleSidebar
}: ProfileButtonProps) {
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowProfileMenu(!showProfileMenu);
    onToggle();
  };

  return (
    <button
      className="w-full flex items-center relative py-2"
      onClick={handleClick}
    >
      <div className="relative">
        <img
          className={`rounded-full border ${toggleSidebar ? 'h-12 w-12' : 'h-8 w-8 flex justify-center'}`}
          src={picture}
          alt="Profile"
        />
        <div
          className={`absolute w-4 h-4 rounded-full ${availabilityColor} right-0 bottom-0 border border-white`}
        />
      </div>
      {toggleSidebar && <span className="ml-4 text-white">{label}</span>}
    </button>
  );
}
