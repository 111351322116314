import React, { ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";

const styles = {
  subLink: "ml-5 py-1 border-l hover:bg-cornflower-blue-400 rounded",
  subLinkActive: "ml-5 py-1 rounded border-l bg-cornflower-blue-700",
  tooltip: "absolute left-[70%] ml-2 p-2 rounded bg-cornflower-blue-500 text-white whitespace-nowrap z-1000",
};

export default function ExpandableMenuLink({
  label,
  icon,
  onExpand,
  expanded,
  children,
  visited,
  collapsed,
  toggleSidebar
}: {
  label: string;
  icon: ReactNode;
  onExpand: () => void;
  expanded: boolean;
  children: ReactNode;
  visited: boolean;
  collapsed: boolean;
  toggleSidebar: boolean;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const iconClassName = `transition fa-solid fa-angle-down ${expanded ? "fa-rotate-180" : ""}`;
  const styling = collapsed
    ? `rounded w-full text-left flex items-center border-l-4 border-transparent ${
        visited ? "border-cornflower-blue-400 text-cornflower-blue-400" : ""
      }`
    : `${
        visited
          ? "bg-cornflower-blue-700 rounded"
          : "h-9 mb-1 mt-1 rounded w-full text-left flex items-center hover:bg-cornflower-blue-400 dark:hover:bg-stone-200"
      }`;

  const subLinks: Array<{ label: string; to: string }> = React.Children.map(
    children,
    (child) => {
      if (React.isValidElement(child) && child.props) {
        const { label, to } = child.props;
        return { label, to };
      }
      return null;
    }
  )?.filter((link): link is { label: string; to: string } => link !== null) || [];

  return (
    <>
      <div
        className={styling}
        onClick={onExpand}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="flex w-full py-2">
          <div className="flex items-center ml-2">{icon}</div>
          <div className="flex items-center pl-4 w-full" style={{ fontSize: 16 }}>
            {label}
            {toggleSidebar && (
              <div className="flex ml-auto pr-4">
                <i className={iconClassName}></i>
              </div>
            )}
          </div>
        </div>
        {isHovered && !toggleSidebar && (
          <div className={styles.tooltip} onMouseEnter={() => setIsHovered(true)}>
            {subLinks.map((subLink) => (
              <NavLink
                key={subLink.to}
                to={subLink.to}
                className="block px-2 py-2 hover:bg-cornflower-blue-300 rounded"
              >
                {subLink.label}
              </NavLink>
            ))}
          </div>
        )}
      </div>
      {toggleSidebar && expanded && <div className="flex flex-col flex-none">{children}</div>}
    </>
  );
}
