import React, { useState } from "react";
import { NavLink } from "react-router-dom";

interface MainLinkProps {
  to?: string;
  label: string;
  icon: React.ReactNode;
  onVisit: () => void;
  showTooltip: boolean;
  toggleSidebar: boolean;
}

const styles = {
  mainLink: "h-9 my-2 rounded w-full text-left flex items-center hover:bg-cornflower-blue-400 dark:hover:bg-stone-200",
  mainLinkActive: "h-9 my-2 rounded border-lg w-full text-left flex items-center bg-cornflower-blue-700",
  navLink: "h-9 my-2 rounded w-full text-left flex items-center hover:bg-cornflower-blue-400 dark:hover:bg-stone-200 border-transparent",
  tooltip: "absolute ml-2 p-4 rounded bg-cornflower-blue-500 text-white whitespace-nowrap z-50",
};

export default function MainLink({
  to,
  label,
  icon,
  onVisit,
  showTooltip,
  toggleSidebar
}: MainLinkProps) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {toggleSidebar ? (
        <NavLink
          to={to || ""}
          className={({ isActive }) => (isActive ? styles.mainLinkActive : styles.navLink)}
          onClick={onVisit}
        >
          <div className="flex items-center ml-2">
            {icon}
            {showTooltip && <span className="pl-4" style={{ fontSize: 16 }}>{label}</span>}
          </div>
        </NavLink>
      ) : (
        <div
          className={styles.mainLink}
          onClick={onVisit}
        >
          <div className="flex items-center ml-2">
            {icon}
            {showTooltip && <span className="pl-4" style={{ fontSize: 16 }}>{label}</span>}
          </div>
        </div>
      )}

      {!showTooltip && isHovered && (
        <NavLink to={to || ""}>
          <div 
            className={styles.tooltip}
            style={{
              left: `calc(70%)`,
              transform: "translateY(-80%)",
            }}
          >
            {label}
          </div>
        </NavLink>
      )}
    </div>
  );
}
